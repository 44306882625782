import React, {memo, PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react'
import {Select} from 'antd'
import {buildWorkspaceUri} from "components/workspace/Workspace.types"
import styled from "styled-components"
import {HomeIcon} from "@heroicons/react/solid"
import {IconContainer} from "@biron-data/react-components"
import {ChevronDownIcon, SearchIcon, XIcon} from "@heroicons/react/outline"
import {useNavigate} from "react-router-dom"
import {ExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"

const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g // from https://github.com/sindresorhus/escape-string-regexp/blob/master/index.js

interface Props extends PropsWithChildren {
  currentWorkspace: ExpandedWorkspace
  workspaces: ExpandedWorkspace[]
}

type ValueType = ExpandedWorkspace | SummarizedWorkspace

// eslint-disable-next-line react/display-name
const MainLayoutWorkspaceSelector = memo<Props>(({currentWorkspace, workspaces, children}) => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const selectorFocus = useRef<boolean>()
  const selectWithFocus = useRef<boolean>()
  const isHoverWorkspaceList = useRef<boolean>()
  const [search, setSearch] = useState<string>()

  const workspaceName = useMemo(() => workspaces.find(w => w.id === currentWorkspace.id)?.name, [currentWorkspace.id, workspaces])

  const onChange = useCallback((w: ValueType) => {
      navigate(buildWorkspaceUri(w))
    },
    [navigate])

  const searchPattern = useMemo(() => {
    if (search) {
      const filterRegexpPattern = search.replace(matchOperatorsRegex, (ch) => {
        if (ch === '*') {
          return '.*'
        }
        if (ch === '?') {
          return '.'
        }
        return `\\${ch}`
      })
      return new RegExp(`${filterRegexpPattern}`, 'i')
    } else {
      return null
    }
  }, [search])

  const filteredWorkspaces = useMemo(() => searchPattern
      ? workspaces.filter(w => w.name && searchPattern.test(w.name.toLocaleLowerCase()))
      : workspaces
    , [searchPattern, workspaces])

  const close = useCallback(() => {
    setVisible(false)
    setSearch('')
  }, [])

  const open = useCallback(() => {
    setVisible(true)
  }, [])

  const indexOfCurrentWorkspace = useMemo(() => filteredWorkspaces.findIndex(w => w.id === currentWorkspace.id), [currentWorkspace.id, filteredWorkspaces])

  return <Content $visible={visible ? 1 : 0} onMouseEnter={() => {
    selectorFocus.current = true
  }}
                  onMouseLeave={() => {
                    selectorFocus.current = false
                  }}>
    <SelectContainer
      title={workspaceName}>
      <StyledSelect<any>
        $visible={visible ? 0 : 1}
        showSearch={true}
        searchValue={search}
        autoClearSearchValue={false}
        onSearch={(searchValue: string) => {
          if (searchValue === "") {
            if (selectWithFocus.current) {
              setSearch('')
            }
          } else {
            setSearch(searchValue)
          }
        }}
        placeholder={
          <FlexDiv $visible={visible ? 0 : 1}>
            <IconContainer size={17} color={"var(--light-grey)"}>
              {visible ? <SearchIcon/> : <HomeIcon/>}
            </IconContainer>
            <span>{workspaceName}</span>
          </FlexDiv>}
        optionFilterProp="children"
        onChange={onChange}
        variant="borderless"
        virtual={false}
        onDropdownVisibleChange={(state: boolean) => {
          if (state) {
            setVisible(state)
          } else if (!selectorFocus.current && !isHoverWorkspaceList.current) {
            setVisible(state)
          }
          selectWithFocus.current = state
        }}
        dropdownStyle={{display: 'none'}}
        suffixIcon={null}>
      </StyledSelect>
      <CloseButton>
        {visible ? <IconContainer onClick={() => close()} color={"var(--light-grey)"}>
          <XIcon/>
        </IconContainer> : <IconContainer onClick={() => open()} color={"var(--light-grey)"}>
          <ChevronDownIcon/>
        </IconContainer>
        }
      </CloseButton>
    </SelectContainer>
    <SelectorContent $visible={visible ? 0 : 1} onMouseEnter={() => {
      isHoverWorkspaceList.current = true
    }}
                     onMouseLeave={() => {
                       isHoverWorkspaceList.current = false
                     }}>
      {visible ? filteredWorkspaces.map((w, i) => <ListElement key={String(w.id)} {...{
        title: w.name,
        onClick: () => onChange(w),
        ref: i === indexOfCurrentWorkspace ? (ref) => {
          ref?.scrollIntoView()
        } : undefined,
      }}
      >{w.name}</ListElement>) : children}
    </SelectorContent>
  </Content>
})

export default MainLayoutWorkspaceSelector

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
`

const FlexDiv = styled.div<{ $visible: number }>`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  
  span {
      color: ${({$visible}) => $visible ? "white" : "#94949E"};
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
`

const Content = styled.div<{ $visible: number }>`
  flex: 1 0 73px;
  min-height: 0;
  display: flex;
  flex-direction: column;
  ${({$visible}) => $visible ? "border-bottom: 1px solid var(--main-text-color);" : ""}
`

const SelectorContent = styled.div<{ $visible: number }>`
  min-height: 0;
  overflow-y: hidden;

    &:hover {
    overflow-y: auto;
  }
  ${({$visible}) => $visible ? "" : "background-color: #19191a;"}
`

const ListElement = styled.div`
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
    line-height: var(--line-height);

    &:hover {
    background-color: var(--workspace-selector-background) !important;
  }
`

const SelectContainer = styled.div`
  height: 73px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  
  .ant-select .ant-select-selector{
    cursor: pointer !important;
    border-radius: 0px !important;
  }
`

const StyledSelect = styled(Select)<{ $visible: number }>`
  width: 90%;
  
  &&& .ant-select-selector {
    background-color: var(--workspace-selector-background) !important;
    color: white !important;
    border-radius: 4px !important;
    ${({$visible}) => $visible ? "" : `
      border-color: #989EA4 !important;
      background-color: var(--hover-background) !important;
    `}
    &:hover {
      background-color: var(--hover-background) !important;
    }
    
    input {
      cursor: pointer;
    }
  }
`
