/* eslint-disable max-lines */
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"

const initialState: {
  uid?: string,
  environmentId?: number
} = {}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    set(state, {uid, environmentId}: {
      uid?: string,
      environmentId?: number
    }) {
      return {uid, environmentId}
    },
  },
  effects: (dispatch) => {
    return {
      init() {
        const lastEditedDashboard = localStorage.getItem('lastOpenedPersonalDashboard')
        if (lastEditedDashboard) {
          dispatch.lastEditedDashboard.set(JSON.parse(lastEditedDashboard))
        }
      },
      update({uid, environmentId}: {uid: string, environmentId: number}, state) {
        const lastOpenedPersonalDashboard: {
          uid: string,
          environmentId: number
        } | undefined = localStorage.getItem('lastOpenedPersonalDashboard') ? JSON.parse(localStorage.getItem('lastOpenedPersonalDashboard') ?? '') : undefined
        if (lastOpenedPersonalDashboard?.uid !== uid) {
          localStorage.setItem('lastOpenedPersonalDashboard', JSON.stringify({
            uid,
            environmentId: Number(environmentId),
          }))
        }
        dispatch.lastEditedDashboard.set({
          uid,
          environmentId: Number(environmentId),
        })
      },
    }
  },
})
