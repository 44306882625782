import {IconContainer, TooltipPopover} from "@biron-data/react-components"
import {BookmarkIcon, DocumentIcon, PlusIcon} from "@heroicons/react/outline"
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react"
import {getPersonalDashboards} from "redux/personalDashboard.selector"
import {useSelector} from "react-redux"
import {Folder} from "types/dashboards"
import useDispatch from "hooks/useDispatch"
import {useNavigate} from "react-router-dom"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {
  useCurrentUid, useDashboardsSort,
  useGetDraftCategory, useLastSevenDaysDrafts, useLastThirtyDaysDrafts, useLastUpdatedDraft, useOlderDrafts,
  useRestore, useStarredDrafts,
  useTodayDrafts, useTrashedDrafts,
} from "components/mainLayout/menu/PersonalDashboardMenu.hooks"
import {Container, FlexContainer, StyledMenu, StyledMenuWithTopBorder} from "components/mainLayout/menu/PersonalDashboardMenu.style"
import AnimateHeight from "react-animate-height"
import Language from "language"

interface PersonalDashboardMenuProps {
  workspaceUriBuilder: (pathOrDashboard?: string | NormalizedDashboardTypes) => string
}

const PersonalDashboardMenu: FunctionComponent<PersonalDashboardMenuProps> = ({workspaceUriBuilder}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const environmentId = useSelector(getCurrentEnvironmentId)
  const personalDashboards = useSelector(getPersonalDashboards)

  const currentUid = useCurrentUid()
  const sortDashboards = useDashboardsSort()
  const draftDashboards = useMemo(() => personalDashboards.filter(personalDashboard => personalDashboard.folder === Folder.DRAFT).sort(sortDashboards), [personalDashboards, sortDashboards])
  const starredDashboards = useMemo(() => personalDashboards.filter(personalDashboard => personalDashboard.folder === Folder.STARRED).sort(sortDashboards), [personalDashboards, sortDashboards])
  const trashedDashboards = useMemo(() => personalDashboards.filter(personalDashboard => personalDashboard.folder === Folder.TRASH).sort(sortDashboards), [personalDashboards, sortDashboards])

  const [openDraftKeys, setOpenDraftKeys] = useState<string[]>([])

  useEffect(() => {
    if ([...draftDashboards, ...starredDashboards].find(draftDashboard => draftDashboard.uid === currentUid)) {
      setOpenDraftKeys(old => old.find(key => key === 'draft') ? old : [...old, 'draft'])
    } else if (trashedDashboards.find(draftDashboard => draftDashboard.uid === currentUid)) {
      setOpenDraftKeys(old => old.find(key => key === 'draft') && old.find(key => key === 'trash') ? old : [...old, 'draft', 'trash'])
    }
    // There is no need to recompute after list modification as the opened menu are only impacted by currentUid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUid])

  const redirect = useCallback((removedUid: string) => {
    const otherDraft = draftDashboards.filter(dashboard => ![currentUid, removedUid].includes(dashboard.uid))
    if(otherDraft.length === 0) {
      navigate(workspaceUriBuilder())
    }
    navigate(`/app/personal/${environmentId}/${otherDraft.sort(sortDashboards)[0].uid}`)
  }, [currentUid, draftDashboards, environmentId, navigate, sortDashboards, workspaceUriBuilder])

  const restore = useRestore(dispatch.personalDashboards.updatePersonalDashboardFolder)

  const getDraftCategory = useGetDraftCategory(draftDashboards, dispatch.personalDashboards.updatePersonalDashboardFolder, restore, redirect)
  const todayDrafts = useTodayDrafts(getDraftCategory)
  const lastSevenDaysDrafts = useLastSevenDaysDrafts(getDraftCategory)
  const lastThirtyDaysDrafts = useLastThirtyDaysDrafts(getDraftCategory)
  const olderDrafts = useOlderDrafts(getDraftCategory)

  const starredDrafts = useStarredDrafts(starredDashboards, dispatch.personalDashboards.updatePersonalDashboardFolder)
  const trashedDrafts = useTrashedDrafts(trashedDashboards, restore)

  const lastModifiedDraft = useLastUpdatedDraft(currentUid, environmentId)

  return <Container>
    <StyledMenuWithTopBorder
      theme={"biron" as any /* Menu types does not register additional themes */}
      mode="inline"
      inlineIndent={16}
      subMenuOpenDelay={.5}
      triggerSubMenuAction={"click"}
      selectedKeys={[currentUid]}
      openKeys={openDraftKeys}
      onOpenChange={(keys) => {
        setOpenDraftKeys(keys)
      }}
      onClick={(e) => {
        if (e.key === "draft") {
          return
        }
        navigate(`/app/personal/${environmentId}/${e.key}`)
      }}
      items={[
        {
          key: 'draft',
          label: <FlexContainer>
            {Language.get(`personal-dashboard.folder.${Folder.DRAFT}`)}
            <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.add')}>
              <FlexContainer><IconContainer clickable={true} onClick={async (e) => {
                e.stopPropagation()
                const dashboard = await dispatch.personalDashboards.addPersonalDashboard(undefined)
                navigate(`/app/personal/${environmentId}/${dashboard.uid}`)
              }}><PlusIcon/></IconContainer>
              </FlexContainer>
            </TooltipPopover>
          </FlexContainer>,
          children: [
            ...starredDrafts,
            ...todayDrafts,
            ...lastSevenDaysDrafts,
            ...lastThirtyDaysDrafts,
            ...olderDrafts,
            ...trashedDrafts,
          ],
        },
      ]}
    />
    <AnimateHeight
      duration={500}
      height={openDraftKeys.find(key => key === "draft") ? 0 : 'auto'}
    >
      <StyledMenu
        theme={"biron" as any /* Menu types does not register additional themes */}
        mode="inline"
        inlineIndent={16}
        selectedKeys={[currentUid]}
        subMenuOpenDelay={.5}
        onClick={(e) => {
          if (lastModifiedDraft) {
            navigate(`/app/personal/${lastModifiedDraft.environmentId}/${e.key}`)
          }
        }}
        items={lastModifiedDraft?.title ? [
          {
            key: lastModifiedDraft.uid,
            label: <FlexContainer>
              {lastModifiedDraft.title}
            </FlexContainer>,
            icon: <IconContainer size={14} margin={10}>
              {lastModifiedDraft.folder === Folder.STARRED ? <BookmarkIcon/> : <DocumentIcon/>}
            </IconContainer>,
          },
        ] : []}
      />
    </AnimateHeight>
  </Container>
}

export default PersonalDashboardMenu
