import React, {FunctionComponent, ReactNode} from 'react'
import styled from "styled-components"
import {DashboardWithoutChartsTypes} from "schemas/dashboard"

interface Props {
  dashboard: DashboardWithoutChartsTypes
  dashboardTitlePrefix?: ReactNode
}

const DashboardTitle: FunctionComponent<Props> = ({dashboard, dashboardTitlePrefix}) => {
  return <StyledContainer>
    {dashboardTitlePrefix && <>
      <MenuTitle>
        {dashboardTitlePrefix}
      </MenuTitle>
      <Separator>
        {"/"}
      </Separator></>}
    <span>
      {dashboard.title}
    </span>
  </StyledContainer>
}

export default DashboardTitle

const MenuTitle = styled.div`
  color: var(--primary);
`

const Separator = styled.div`
  color: var(--border-color-base);
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 300;
`

const StyledContainer = styled.div`
  display: inline-flex;
  flex-shrink: 1;
  flex-grow: 1;
  font-size: 24px;
  font-weight: 600;
`
